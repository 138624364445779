<template>
  <div v-if="session">
    <v-data-table
      :headers="headers"
      :items="rwskps"
      :mobile-breakpoint="0"
      :items-per-page="-1"
      hide-default-footer
      style="text-overflow: ellipsis; white-space: nowrap"
    >
      <template v-slot:top>
        <v-alert v-model="alertBerhasil" type="success" dense dismissible>
          {{ alertMassage }}
        </v-alert>
        <v-alert v-model="alertGagal" type="error" dense dismissible>
          {{ alertMassage }}
        </v-alert>
        <rwskpModalEdit />
        <rwskpModalHapus />
      </template>
      <template v-slot:[`item.predikat`]="{ item }">
        {{ predikat(item.nilai.nilairatarata) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-1" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import rwskpModalEdit from "@/components/asn/profil/rwskp/skp_old/ModalEdit";
import rwskpModalHapus from "@/components/asn/profil/rwskp/skp_old/ModalHapus";

import modal_editState from "@/store/asn/rwskp/modal_edit";
import modal_hapusState from "@/store/asn/rwskp/modal_hapus";
import refreshView from "@/store/asn/rwskp/viewRwskp";

export default {
  name: "rwskp",

  components: {
    rwskpModalEdit,
    rwskpModalHapus,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },

      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },
      set(value) {
        // console.log('Tutup Alert: ' + value)
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },

      set(value) {
        // console.log('Tutup Alert: ' + value)
        refreshView.commit("gagalAlert", value);
      },
    },

    headers() {
      const headers = [
        { text: "Tahun", align: "start", value: "tahun" },
        { text: "Rata-rata", value: "nilai.nilairatarata" },
        { text: "Predikat", value: "predikat" },
        { text: "Jumlah", value: "nilai.jumlah" },
        { text: "Pejabat Penilai", value: "pejabatPenilai.penilaiNama" },
        {
          text: "Atasan Pejabat Penilai",
          value: "atasanPejabatPenilai.atasanPenilaiNama",
        },
      ];

      if (this.session.authorities.some((item) => item === "CRUD_SKP")) {
        headers.push({
          text: "Actions",
          value: "actions",
          align: "center",
          width: "80px",
          sortable: false,
        });
      }

      return headers;
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    session: "",

    rwskps: [],
    rwskp: {},
    dataUtama: {},
    get_nip: "",

    ModalAdd: false,
    loading: false,

    // ModalEdit: false,
    dialog: false,

    //Kebutuhan Edit
    editedIndex: -1,
    hapusIndex: -1,
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.get_nip = this.$route.params.id;
    this.getData();
  },

  methods: {
    getData() {
      this.loading = true;
      const url = process.env.VUE_APP_ASN + "skp/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.loading = false;
          refreshView.commit("refreshData", false);
          this.rwskps = response.data.mapData.RwSKP;
          this.dataUtama = response.data.mapData.DataUtama;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.rwskps.indexOf(item);
      this.rwskp = Object.assign({}, item);
      modal_editState.commit("toggleModal", true);
      modal_editState.commit("editModal", Object.assign({}, item));
      modal_editState.commit(
        "editDatautama",
        Object.assign({}, this.dataUtama)
      );
    },

    deleteItem(item) {
      this.hapusIndex = this.rwskps.indexOf(item);
      this.rwjabatan = Object.assign({}, item);
      modal_hapusState.commit("toggleModal", true);
      modal_hapusState.commit("hapusModal", Object.assign({}, item));
    },

    predikat(value) {
      let angka = parseInt(value);
      let predikat;
      if (angka <= 50) {
        predikat = "Sangat Kurang";
      } else if (angka >= 51 && angka <= 61) {
        predikat = "Kurang";
      } else if (angka >= 61 && angka <= 75) {
        predikat = "Cukup";
      } else if (angka >= 76 && angka <= 90) {
        predikat = "Baik";
      } else if (angka >= 91 && angka <= 100) {
        predikat = "Sangat Baik";
      } else {
        predikat = "Tidak Ada Dalam Daftar Predikat";
      }

      return predikat;
    },
  },
};
</script>
