<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="rwskp_SAPK"
      :mobile-breakpoint="0"
      :items-per-page="-1"
      :loading="loading"
      hide-default-footer
    >
      <template v-slot:top>
        <v-alert v-model="alertBerhasil" type="success" dense dismissible>
          {{ alertMassage }}
        </v-alert>
        <v-alert v-model="alertGagal" type="error" dense dismissible>
          {{ alertMassage }}
        </v-alert>
        <rwskpModalToSIMASN />
      </template>
      <template v-slot:[`item.predikat`]="{ item }">
        {{ predikat(item.nilai.nilairatarata) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="toSIMASN(item)"> mdi-database-arrow-left </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Cookie from '@/helper/cookie.js'
import Auth from '@/helper/auth.js'

import rwskpModalToSIMASN from '@/components/asn/profil/rwskp/skp_old/ModalToSIMASN'

import refreshView from '@/store/asn/rwskp/tabs'
import refreshData from '@/store/asn/rwskp/viewRwskp'
import modal_tosimasnState from '@/store/asn/rwskp/modal_to_simasn'

export default {
  components: {
    rwskpModalToSIMASN,
  },

  computed: {
    tabSAPK: {
      get() {
        return refreshView.state.tabSAPK
      },
    },

    sapkID: {
      get() {
        return refreshView.state.sapkID
      },
    },

    refresh: {
      get() {
        return refreshData.state.RefreshSAPK
      },
    },

    alertMassage: {
      get() {
        return refreshData.state.alertMassage
      },
    },

    success: {
      get() {
        return refreshData.state.success
      },

      set(value) {
        refreshData.commit('alert', value)
      },
    },

    alertBerhasil: {
      get() {
        return refreshData.state.alertBerhasil
      },

      set(value) {
        refreshData.commit('berhasilAlert', value)
      },
    },

    alertGagal: {
      get() {
        return refreshData.state.alertGagal
      },

      set(value) {
        refreshData.commit('gagalAlert', value)
      },
    },
  },

  watch: {
    tabSAPK(newVal) {
      if (newVal) {
        this.getDataSAPK()
      }
    },
  },

  data: () => ({
    token: Cookie.get('token'),
    token_siasn: Cookie.get('token_siasn'),
    token_wsbkn: Cookie.get('token_wsbkn'),

    Modal_rwskp_SAPK: false,
    loading: false,
    rwskp_SAPK: [],
    get_nip: '',

    headers: [
      { text: 'Tahun', align: 'start', value: 'tahun' },
      { text: 'Rata-rata', value: 'nilai.nilairatarata' },
      { text: 'Predikat', value: 'predikat' },
      { text: 'Jumlah', value: 'nilai.jumlah' },
      { text: 'Pejabat Penilai', value: 'pejabatPenilai.penilaiNama' },
      {
        text: 'Atasan Pejabat Penilai',
        value: 'atasanPejabatPenilai.atasanPenilaiNama',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '80px',
      },
    ],
  }),

  async mounted() {
    this.get_nip = this.$route.params.id
    if (this.tabSAPK) {
      this.getDataSAPK()
    }
  },

  methods: {
    getDataSAPK() {
      this.loading = true

      let data = {
        siasn_token: this.token_siasn,
        wsbkn_token: this.token_wsbkn,
      }

      const url = process.env.VUE_APP_ASN + 'skp/nip/' + this.get_nip + '/bkn'

      this.http
        .post(url, data, {
          headers: {
            Authorization: 'Bearer ' + this.token,
          },
        })
        .then((response) => {
          this.loading = false
          this.rwskp_SAPK = response.data.mapData.RwSkp
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status)
          } else {
            console.log('Error', error.message)
          }
        })
    },

    predikat(value) {
      let predikat
      if (value <= 50) {
        predikat = 'Kurang'
      } else if (value >= 51 && value <= 61) {
        predikat = 'Sedang'
      } else if (value >= 61 && value <= 75) {
        predikat = 'Cukup'
      } else if (value >= 76 && value <= 90) {
        predikat = 'Baik'
      } else {
        predikat = 'Amat Baik'
      }

      return predikat
    },

    toSIMASN(item) {
      modal_tosimasnState.commit('toggleModal', true)
      modal_tosimasnState.commit('tosimasnModal', Object.assign({}, item))
      modal_tosimasnState.commit('Datautama', Object.assign({}, this.dataUtama))
    },
  },
}
</script>
