var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.session)?_c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-app-bar',{attrs:{"color":"primary","short":"","dense":"","dark":"","flat":""}},[_c('v-toolbar-title',[_c('span',[_vm._v(" Riwayat Kinerja & SKP ")])]),_c('v-spacer'),_c('div',{staticClass:"mr-3 mt-1"},[(
            _vm.session.authorities.some((item) => item === 'CRUD_SKP') &&
            _vm.tahun == 1
          )?_c('div',[_c('rwskp_new_add')],1):_vm._e(),(
            _vm.session.authorities.some((item) => item === 'CRUD_SKP') &&
            _vm.tahun == 2
          )?_c('div',[_c('rwskp_old_add')],1):_vm._e()]),_c('div',{staticStyle:{"max-width":"300px"}},[_c('v-tabs',{attrs:{"right":"","dark":"","background-color":"primary"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#tab-1"},on:{"click":function($event){return _vm.getSIMASN()}}},[_c('h5',[_vm._v("IDASN")])]),_c('v-tab',{attrs:{"href":"#tab-2"},on:{"click":function($event){return _vm.getSAPK()}}},[_c('h5',[_vm._v("BKN")])])],1)],1)],1),_c('v-row',{staticClass:"mt-2 mx-3 mb-n9"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.items,"label":"Pilih Tahun SKP","item-value":"id","item-text":"nama","solo":"","dense":""},model:{value:(_vm.tahun),callback:function ($$v) {_vm.tahun=$$v},expression:"tahun"}})],1)],1),_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"pa-5",attrs:{"value":"tab-1"}},[(_vm.tahun == 1)?_c('rwskp_new'):_c('rwskp_old')],1),_c('v-tab-item',{staticClass:"pa-5",attrs:{"value":"tab-2"}},[(_vm.tahun == 1)?_c('rwskpSAPK_new'):_c('rwskpSAPK_old')],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }