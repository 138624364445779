<template>
  <div v-if="session">
    <v-data-table
      :headers="headers"
      :items="rwskps"
      :mobile-breakpoint="0"
      :items-per-page="-1"
      hide-default-footer
    >
      <template v-slot:top>
        <v-alert v-model="alertBerhasil" type="success" dense dismissible>
          {{ alertMassage }}
        </v-alert>
        <v-alert v-model="alertGagal" type="error" dense dismissible>
          {{ alertMassage }}
        </v-alert>

        <rwskpModalEdit />
        <rwskpModalHapus />
      </template>

      <template v-slot:[`item.dokumen`]="{ item }">
        <div v-if="item.document.repo_891 != null">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="download(item.document)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="red"> mdi-file-pdf-box </v-icon>
              </v-btn>
            </template>
            <span>{{ item.document.slug_891.dok_nama }}</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-1" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="modalPDF" :max-width="dekstop">
      <v-card height="90vh" style="overflow: hidden">
        <v-toolbar dark color="primary" dense flat>
          <v-toolbar-title>{{ namaPDF }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closePDF()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card flat class="pa-2">
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="lbtn"
          ></v-progress-linear>

          <div v-else>
            <embed
              :src="defaultPDF"
              type="application/pdf"
              style="height: 80vh; width: 100%"
            />
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import rwskpModalEdit from "@/components/asn/profil/rwskp/skp_new/ModalEdit";
import rwskpModalHapus from "@/components/asn/profil/rwskp/skp_new/ModalHapus";

import modal_edit from "@/store/asn/rwskp/rwskp_new/modal_edit";
import modal_hapus from "@/store/asn/rwskp/rwskp_new/modal_hapus";
import refreshView from "@/store/asn/rwskp/viewRwskp";

export default {
  components: {
    rwskpModalEdit,
    rwskpModalHapus,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },

      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },
      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },

      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },

    headers() {
      const headers = [
        { text: "Tahun", align: "start", value: "tahun" },
        { text: "Hasil Kinerja", value: "kinerja.hasilKinerja" },
        { text: "Perilaku kerja", value: "kinerja.perilakuKerja" },
        { text: "Kuadran Kinerja", value: "kinerja.kuadranKinerja" },
        {
          text: "File",
          value: "dokumen",
          width: "80px",
          align: "center",
          sortable: false,
        },
      ];

      if (this.session.authorities.some((item) => item === "CRUD_SKP")) {
        headers.push({
          text: "Actions",
          value: "actions",
          align: "center",
          width: "80px",
          sortable: false,
        });
      }

      return headers;
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    token_siasn: Cookie.get("token_siasn"),
    token_wsbkn: Cookie.get("token_wsbkn"),

    loading: false,
    session: "",
    get_nip: "",

    modalPDF: false,
    lbtn: false,
    dekstop: "75%",
    defaultPDF: "",
    namaPDF: "",

    rwskps: [],
    dataUtama: {},
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.get_nip = this.$route.params.id;
    this.getData();
  },

  methods: {
    getData() {
      this.loading = true;
      const url = process.env.VUE_APP_ASN + "kinerja/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.loading = false;
          refreshView.commit("refreshData", false);
          this.rwskps = response.data.mapData.RwSKP;
          this.dataUtama = response.data.mapData.DataUtama;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    editItem(item) {
      modal_edit.commit("toggleModal", true);
      modal_edit.commit("editModal", item);
    },

    deleteItem(item) {
      modal_hapus.commit("toggleModal", true);
      modal_hapus.commit("hapusModal", item);
    },

    download(value) {
      this.lbtn = true;
      this.modalPDF = true;
      this.namaPDF = "Realisasi SKP";

      const url = process.env.VUE_APP_API + value.repo_891;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
          responseType: "blob",
        })
        .then((res) => {
          this.defaultPDF = URL.createObjectURL(res.data);
          this.lbtn = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    closePDF() {
      this.modalPDF = false;
    },
  },
};
</script>
