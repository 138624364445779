import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        ModalToSIMASN: false,

        dataUtama: {
            id: '',
            sapk: {
                id: ''
            },
            nipBaru: '',
            nipLama: ''
        },

        rwskp: {
            id: '',
            sapk: {
                id: ''
            },
            jenisJabatan: '',
            tahun: '',
            nilai: {
                nilaiSkp: '',
                orientasiPelayanan: '',
                integritas: '',
                komitmen: '',
                disiplin: '',
                kerjasama: '',
                nilaiPerilakuKerja: '',
                nilaiPrestasiKerja: '',
                kepemimpinan: '',
                jumlah: '',
                nilairatarata: ''
            },

            pejabatPenilai: {
                penilaiNipNrp: '',
                penilaiNama: '',
                penilaiUnorNama: '',
                penilaiJabatan: '',
                penilaiGolongan: '',
                penilaiTmtGolongan: '',
                statusPenilai: ''
            },

            atasanPejabatPenilai: {
                atasanPenilaiNipNrp: '',
                atasanPenilaiNama: '',
                atasanPenilaiUnorNama: '',
                atasanPenilaiJabatan: '',
                atasanPenilaiGolongan: '',
                atasanPenilaiTmtGolongan: '',
                statusAtasanPenilai: ''
            },

            pnsUserId: '',
            NCSISTIME: ''
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.ModalToSIMASN = value
        },

        tosimasnModal(state, value) {
            state.rwskp = value
        },
        
        Datautama(state, value) {
            state.dataUtama = value
        }
    }
})