<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="rwskp_SAPK"
      :mobile-breakpoint="0"
      :items-per-page="-1"
      :loading="loading"
      hide-default-footer
    >
      <template v-slot:top>
        <v-alert v-model="alertBerhasil" type="success" dense dismissible>
          {{ alertMassage }}
        </v-alert>
        <v-alert v-model="alertGagal" type="error" dense dismissible>
          {{ alertMassage }}
        </v-alert>
        <rwskpModalToSIMASN />
      </template>

      <!-- <template v-slot:[`item.dokumen`]="{ item }">
        <div v-if="Object.keys(item.document).length > 0">
          <v-tooltip
            bottom
            v-if="Object.keys(item.document.slug_891).length > 2"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="download(item.document.slug_891)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="red"> mdi-file-pdf-box </v-icon>
              </v-btn>
            </template>
            <span>{{ item.document.slug_891.dok_nama }}</span>
          </v-tooltip>
        </div>
      </template> -->

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="toSIMASN(item)" v-bind="attrs" v-on="on">
              <v-icon color="primary"> mdi-database-arrow-left </v-icon>
            </v-btn>
          </template>
          <span>Salin Data</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-model="modalPDF" :max-width="dekstop">
      <v-card height="90vh" style="overflow: hidden">
        <v-toolbar dark color="primary" dense flat>
          <v-toolbar-title>{{ namaPDF }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closePDF()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card flat class="pa-2">
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="lbtn"
          ></v-progress-linear>

          <div v-else>
            <embed
              :src="defaultPDF"
              type="application/pdf"
              style="height: 80vh; width: 100%"
            />
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import rwskpModalToSIMASN from "@/components/asn/profil/rwskp/skp_new/ModalToSIMASN";

import refreshData from "@/store/asn/rwskp/viewRwskp";
import refreshView from "@/store/asn/rwskp/tabs";
import modal_tosimasnState from "@/store/asn/rwskp/modal_to_simasn";

export default {
  components: {
    rwskpModalToSIMASN,
  },

  computed: {
    tabSAPK: {
      get() {
        return refreshView.state.tabSAPK;
      },
    },

    sapkID: {
      get() {
        return refreshView.state.sapkID;
      },
    },

    refresh: {
      get() {
        return refreshData.state.RefreshSAPK;
      },
    },

    alertMassage: {
      get() {
        return refreshData.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshData.state.success;
      },

      set(value) {
        refreshData.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshData.state.alertBerhasil;
      },

      set(value) {
        refreshData.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshData.state.alertGagal;
      },

      set(value) {
        refreshData.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    tabSAPK(newVal) {
      if (newVal) {
        this.getDataSAPK();
      }
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    token_siasn: Cookie.get("token_siasn"),
    token_wsbkn: Cookie.get("token_wsbkn"),

    Modal_rwskp_SAPK: false,
    loading: false,

    modalPDF: false,
    lbtn: false,
    dekstop: "75%",
    defaultPDF: "",
    namaPDF: "",

    rwskp_SAPK: [],
    get_nip: "",

    headers: [
      { text: "Tahun", align: "start", value: "tahun" },
      { text: "Hasil Kinerja", value: "kinerja.hasilKinerja" },
      { text: "Perilaku kerja", value: "kinerja.perilakuKerja" },
      { text: "Kuadran Kinerja", value: "kinerja.kuadranKinerja" },
      // {
      //   text: "File",
      //   value: "dokumen",
      //   width: "80px",
      //   align: "center",
      //   sortable: false,
      // },
      {
        text: "Actions",
        value: "actions",
        width: "80px",
        align: "center",
        sortable: false,
      },
    ],
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
    if (this.tabSAPK) {
      this.getDataSAPK();
    }
  },

  methods: {
    getDataSAPK() {
      this.loading = true;

      const url =
        process.env.VUE_APP_ASN + "kinerja/nip/" + this.get_nip + "/bkn";

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.loading = false;
          this.rwskp_SAPK = response.data.mapData.RwSkp;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    toSIMASN(item) {
      modal_tosimasnState.commit('toggleModal', true)
      modal_tosimasnState.commit('tosimasnModal', item)
      modal_tosimasnState.commit('Datautama', this.dataUtama)
    },

    download(value) {
      this.lbtn = true;
      this.modalPDF = true;
      this.namaPDF = value.dok_nama;

      let baseurl =
        "https://apimws.bkn.go.id:8243/apisiasn/1.0/download-dok?filePath=";

      const url = baseurl + value.dok_uri;

      this.http
        .get(url, {
          headers: {
            Auth: "bearer " + this.token_siasn,
            Authorization: "Bearer " + this.token_wsbkn,
          },
          responseType: "blob",
        })
        .then((res) => {
          this.defaultPDF = URL.createObjectURL(res.data);
          this.lbtn = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    closePDF() {
      this.modalPDF = false;
    },
  },
};
</script>
