import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalHapus: false,
    rwskp: {
      id: '',
      sapk: {
        id: ''
      },
      jenisJabatan: '',
      tahun: 0,
      jenisPeraturanKinerja: '',
      nilai: {
        nilaiSkp: 0,
        orientasiPelayanan: 0,
        komitmen: 0,
        kerjasama: 0,
        kepemimpinan: 0,
        integritas: 0,
        disiplin: 0,
        inisiatifKerja: 0,
        nilaiPerilakuKerja: 0,
        nilaiPrestasiKerja: 0,
        jumlah: 0,
        nilairatarata: 0
      },

      pejabatPenilai: {
        penilaiNipNrp: '',
        penilaiNama: '',
        penilaiUnorNama: '',
        penilaiJabatan: '',
        penilaiGolongan: '',
        penilaiTmtGolongan: '',
        statusPenilai: ''
      },

      atasanPejabatPenilai: {
        atasanPenilaiNipNrp: '',
        atasanPenilaiNama: '',
        atasanPenilaiUnorNama: '',
        atasanPenilaiJabatan: '',
        atasanPenilaiGolongan: '',
        atasanPenilaiTmtGolongan: '',
        statusAtasanPenilai: ''
      },

      pnsUserId: '',
      NCSISTIME: ''
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalHapus = value
    },
    hapusModal(state, value) {
      state.rwskp = value
    },
  }
})