<template>
  <div v-if="session">
    <v-card flat>
      <v-app-bar color="primary" short dense dark flat>
        <v-toolbar-title>
          <span> Riwayat Kinerja & SKP </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="mr-3 mt-1">
          <div
            v-if="
              session.authorities.some((item) => item === 'CRUD_SKP') &&
              tahun == 1
            "
          >
            <rwskp_new_add />
          </div>

          <div
            v-if="
              session.authorities.some((item) => item === 'CRUD_SKP') &&
              tahun == 2
            "
          >
            <rwskp_old_add />
          </div>
        </div>

        <div style="max-width: 300px">
          <v-tabs v-model="tab" right dark background-color="primary">
            <v-tab href="#tab-1" @click="getSIMASN()">
              <h5>IDASN</h5>
            </v-tab>

            <v-tab href="#tab-2" @click="getSAPK()">
              <h5>BKN</h5>
            </v-tab>
          </v-tabs>
        </div>
      </v-app-bar>

      <v-row class="mt-2 mx-3 mb-n9">
        <v-col cols="12" md="4">
          <v-select
            v-model="tahun"
            :items="items"
            label="Pilih Tahun SKP"
            item-value="id"
            item-text="nama"
            solo
            dense
          ></v-select>
        </v-col>
      </v-row>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item value="tab-1" class="pa-5">
          <rwskp_new v-if="tahun == 1" />
          <rwskp_old v-else />
        </v-tab-item>

        <v-tab-item value="tab-2" class="pa-5">
          <rwskpSAPK_new v-if="tahun == 1" />
          <rwskpSAPK_old v-else />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getData from "@/store/asn/rwskp/tabs";
import rwskp_old from "@/components/asn/profil/rwskp/skp_old/rwskp";
import rwskp_new from "@/components/asn/profil/rwskp/skp_new/rwskp";

import rwskpSAPK_new from "@/components/asn/profil/rwskp/skp_new/SAPK_rwskp_Modal";
import rwskpSAPK_old from "@/components/asn/profil/rwskp/skp_old/SAPK_rwskp_Modal";

import rwskp_old_add from "@/components/asn/profil/rwskp/skp_old/ModalAdd";
import rwskp_new_add from "@/components/asn/profil/rwskp/skp_new/ModalAdd";

export default {
  components: {
    rwskp_old,
    rwskp_new,

    rwskpSAPK_new,
    rwskpSAPK_old,

    rwskp_old_add,
    rwskp_new_add,
  },

  computed: {
    dataSAPK: {
      get() {
        return getData.state.tabSAPK;
      },

      set(value) {
        getData.commit("getSAPK", value);
      },
    },
  },

  data: () => ({
    tab: null,
    session: "",

    tahun: 1,

    items: [
      {
        id: 1,
        nama: "KINERJA",
      },
      {
        id: 2,
        nama: "SKP",
      },
    ],
  }),

  mounted() {
    this.session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
  },

  methods: {
    getSAPK() {
      getData.commit("getSAPK", true);
      getData.commit("getSIMASN", false);
    },

    getSIMASN() {
      getData.commit("getSIMASN", true);
      getData.commit("getSAPK", false);
    },
  },
};
</script>
