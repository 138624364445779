<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title class="subtitle-1"
          >Edit Riwayat Sasaran Kinerja Pegawai</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="closeModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-dialog v-model="dialog" persistent max-width="500">
        <v-card>
          <v-card-title class="headline error--text">
            PERINGATAN!
          </v-card-title>
          <v-card-text class="subtitle-1"
            >Jenis Jabatan Rangkap Tidak Bisa Dipilih.</v-card-text
          >
          <v-card-actions class="pb-5">
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed @click="tutupDialog">
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-5">
          <div>
            <span class="subtitle-1"><b>Riwayat SKP</b></span>
          </div>
          <v-divider class="mt-2 mb-2"></v-divider>

          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Jenis Jabatan / Eselon</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="editedItem.jenisJabatan"
                  :items="refJenisjabatan"
                  item-text="nama"
                  item-value="id"
                  hint="Jenis Jabatan"
                  persistent-hint
                  dense
                  outlined
                  @change="onChange"
                ></v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="editedItem.tahun"
                  hint="Tahun"
                  persistent-hint
                  dense
                  flat
                  outlined
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="this.editedItem.tahun == 2021">
              <v-col cols="12" md="2" class="mt-3">
                <span>Jenis Peraturan Kinerja</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="editedItem.jenisPeraturanKinerja"
                  :items="refjpp"
                  item-text="nama"
                  item-value="id"
                  dense
                  outlined
                  @change="onJPP"
                  :rules="fRules"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <div>
            <span class="subtitle-1">
              <b><i>Sasaran Kinerja Pegawai</i></b>
            </span>
          </div>
          <v-divider class="mt-2 mb-2"></v-divider>

          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nilai SKP</span>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model.number="editedItem.nilai.nilaiSkp"
                  dense
                  flat
                  outlined
                  type="number"
                  maxlength="5"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field
                  v-model.number="enampuluhpersen"
                  dense
                  flat
                  outlined
                  readonly
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model.number="hasilnilaiSKP"
                  dense
                  flat
                  outlined
                  readonly
                  filled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <div>
            <span class="subtitle-1">
              <b><i>Perilaku Kerja</i></b>
            </span>
          </div>
          <v-divider class="mt-2 mb-2"></v-divider>

          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="6" class="ml-n1">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" md="4" class="mt-3">
                      <span>Orientasi Pelayanan</span>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model.number="editedItem.nilai.orientasiPelayanan"
                        dense
                        flat
                        outlined
                        type="number"
                        maxlength="5"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :value="predikat(editedItem.nilai.orientasiPelayanan)"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="mt-n5">
                  <v-row>
                    <v-col cols="12" md="4" class="mt-3">
                      <span>Komitmen</span>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model.number="editedItem.nilai.komitmen"
                        dense
                        flat
                        outlined
                        type="number"
                        maxlength="5"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :value="predikat(editedItem.nilai.komitmen)"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="mt-n5">
                  <v-row>
                    <v-col cols="12" md="4" class="mt-3">
                      <span>Kerjasama</span>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model.number="editedItem.nilai.kerjasama"
                        dense
                        flat
                        outlined
                        type="number"
                        maxlength="5"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :value="predikat(editedItem.nilai.kerjasama)"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="mt-n5">
                  <v-row>
                    <v-col cols="12" md="4" class="mt-3">
                      <span>Nilai Perilaku Kerja</span>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model.number="this.nilaiPerilakuKerja"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model.number="empatpuluhpersen"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model.number="hasilnilaiPerilakuKerja"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="mt-n5">
                  <v-row>
                    <v-col cols="12" md="4" class="mt-3">
                      <span>
                        <b><i>Nilai Prestasi Kerja</i></b>
                      </span>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model.number="nilaiPrestasiKerja"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :value="predikat(nilaiPrestasiKerja)"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-col>

              <v-col cols="12" md="6">
                <v-col cols="12" v-if="integritasView">
                  <v-row>
                    <v-col cols="12" md="3" class="mt-3">
                      <span>Integritas</span>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model.number="editedItem.nilai.integritas"
                        dense
                        flat
                        outlined
                        type="number"
                        maxlength="5"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :value="predikat(editedItem.nilai.integritas)"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="mt-n5" v-if="disiplinView">
                  <v-row>
                    <v-col cols="12" md="3" class="mt-3">
                      <span>Disiplin</span>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model.number="editedItem.nilai.disiplin"
                        dense
                        flat
                        outlined
                        type="number"
                        maxlength="5"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :value="predikat(editedItem.nilai.disiplin)"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" class="mt-n5" v-if="inisiatifkerjaView">
                  <v-row>
                    <v-col cols="12" md="3" class="mt-3">
                      <span>Inisiatif Kerja</span>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model.number="editedItem.nilai.inisiatifKerja"
                        dense
                        flat
                        outlined
                        type="number"
                        maxlength="5"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :value="predikat(editedItem.nilai.inisiatifKerja)"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col
                  cols="12"
                  class="mt-n5"
                  v-if="editedItem.jenisJabatan == 1"
                >
                  <v-row>
                    <v-col cols="12" md="3" class="mt-3">
                      <span>Kepemimpinan</span>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        v-model.number="editedItem.nilai.kepemimpinan"
                        dense
                        flat
                        outlined
                        type="number"
                        maxlength="5"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        :value="predikat(editedItem.nilai.kepemimpinan)"
                        dense
                        flat
                        outlined
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="6">
                <div class="mt-n3">
                  <span class="subtitle-1">
                    <b>Pejabat Penilai</b>
                  </span>
                </div>
                <v-divider class="mt-2 mb-2"></v-divider>
                <v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>NIP / NRP</span>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="editedItem.pejabatPenilai.penilaiNipNrp"
                          dense
                          flat
                          outlined
                          persistent-hint
                          :hint="
                            messageNIPCari != '' ? '* ' + messageNIPCari : ''
                          "
                        ></v-text-field>
                      </v-col>
                      <!-- <v-col cols="12" md="4">
                        <v-btn
                          small
                          depressed
                          color="primary"
                          dark
                          class="mt-1"
                          @click="pejabatPenilai()"
                          v-if="btnLoadingCariPP"
                        >
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn
                          v-else
                          small
                          depressed
                          color="primary"
                          dark
                          class="mt-1"
                          loading
                        >
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                      </v-col> -->
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>Nama</span>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-model="editedItem.pejabatPenilai.penilaiNama"
                          dense
                          flat
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>Jabatan</span>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="editedItem.pejabatPenilai.penilaiJabatan"
                          dense
                          flat
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>Unor</span>
                      </v-col>
                      <v-col cols="12" md="10">
                        <v-text-field
                          v-model="editedItem.pejabatPenilai.penilaiUnorNama"
                          dense
                          flat
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>Golongan</span>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-text-field
                          v-model="editedItem.pejabatPenilai.penilaiGolongan"
                          dense
                          flat
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-menu
                          v-model="ppDate"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="
                                editedItem.pejabatPenilai.penilaiTmtGolongan
                              "
                              hint="TMT Golongan"
                              persistent-hint
                              v-bind="attrs"
                              v-on="on"
                              dense
                              flat
                              outlined
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="
                              editedItem.pejabatPenilai.penilaiTmtGolongan
                            "
                            @input="ppDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-col>
              </v-col>

              <v-col cols="12" md="6">
                <div class="mt-n3">
                  <span class="subtitle-1">
                    <b>Atasan Pejabat Penilai</b>
                  </span>
                </div>
                <v-divider class="mt-2 mb-2"></v-divider>
                <v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>NIP / NRP</span>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="
                            editedItem.atasanPejabatPenilai.atasanPenilaiNipNrp
                          "
                          dense
                          flat
                          outlined
                          persistent-hint
                          :hint="
                            messageAtasanNIPCari != ''
                              ? '* ' + messageAtasanNIPCari
                              : ''
                          "
                        ></v-text-field>
                      </v-col>
                      <!-- <v-col cols="12" md="4">
                        <v-btn
                          small
                          depressed
                          color="primary"
                          dark
                          class="mt-1"
                          @click="atasanPenilai()"
                          v-if="btnLoadingCariAtasanPP"
                        >
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn
                          v-else
                          small
                          depressed
                          color="primary"
                          dark
                          class="mt-1"
                          loading
                        >
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                      </v-col> -->
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>Nama</span>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-model="
                            editedItem.atasanPejabatPenilai.atasanPenilaiNama
                          "
                          dense
                          flat
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>Jabatan</span>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="
                            editedItem.atasanPejabatPenilai.atasanPenilaiJabatan
                          "
                          dense
                          flat
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>Unor</span>
                      </v-col>
                      <v-col cols="12" md="10">
                        <v-text-field
                          v-model="
                            editedItem.atasanPejabatPenilai
                              .atasanPenilaiUnorNama
                          "
                          dense
                          flat
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="2" class="mt-3">
                        <span>Golongan</span>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-text-field
                          v-model="
                            editedItem.atasanPejabatPenilai
                              .atasanPenilaiGolongan
                          "
                          dense
                          flat
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-menu
                          v-model="atasanDate"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="
                                editedItem.atasanPejabatPenilai
                                  .atasanPenilaiTmtGolongan
                              "
                              hint="TMT Golongan"
                              persistent-hint
                              v-bind="attrs"
                              v-on="on"
                              dense
                              flat
                              outlined
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="
                              editedItem.atasanPejabatPenilai
                                .atasanPenilaiTmtGolongan
                            "
                            @input="atasanDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <span class="error--text mr-5" v-if="fKosong"
              >*Harap Periksa Kembali Inputan Anda!</span
            >
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from '@/helper/cookie.js'
import apiURL from '@/helper/getURL.js'
import Auth from '@/helper/auth.js'

import getRef from '@/helper/getReferensi.js'
import modal_editState from '@/store/asn/rwskp/modal_edit'
import refreshView from '@/store/asn/rwskp/viewRwskp'

export default {
  name: 'rwskpModalEdit',

  data() {
    return {
      token: '',
      get_nip: '',
      dialog: false,
      btnLoading: true,
      btnLoadingCariPP: true,
      btnLoadingCariAtasanPP: true,

      atasanDate: false,
      ppDate: false,

      inisiatifkerjaView: false,
      kepemimpinanView: false,
      integritasView: true,
      disiplinView: true,

      messageNIPCari: '',
      messageAtasanNIPCari: '',

      rwskp: {},
      refjpp: [
        {
          id: 'PP46',
          nama: 'Peraturan Pemerintah Nomor 46',
        },
        {
          id: 'PP30',
          nama: 'Peraturan Pemerintah Nomor 30',
        },
      ],

      fRules: [(value) => !!value || 'Tidak Boleh Kosong'],
      fKosong: false,

      // perhitungan
      pembagi: 5,

      // Referensi
      refJenisjabatan: [],

      // Variable edit
      // jenisjabatanID: this.editedItem.Jabatan.jenis.id
    }
  },

  async mounted() {
    this.token = await Cookie.get('token')
    this.get_nip = this.$route.params.id
  },

  computed: {
    ModalEdit: {
      get() {
        return modal_editState.state.ModalEdit
      },

      set(value) {
        modal_editState.commit('toggleModal', value)
      },
    },

    editedItem: {
      get() {
        return modal_editState.state.rwskp
      },

      set(value) {
        console.log(value)
        // modal_editState.commit("editModal", value);
      },
    },

    editedDatautama: {
      get() {
        return modal_editState.state.dataUtama
      },

      set(value) {
        console.log(value)
        // modal_editState.commit("editModal", value);
      },
    },

    enampuluhpersen: function () {
      if (this.editedItem.tahun == 2021) {
        if (this.editedItem.jenisPeraturanKinerja == 'PP30') {
          return '70%'
        } else {
          return '60%'
        }
      } else if (this.editedItem.tahun > 2021) {
        return '70%'
      } else {
        return '60%'
      }
    },

    empatpuluhpersen: function () {
      if (this.editedItem.tahun == 2021) {
        if (this.editedItem.jenisPeraturanKinerja == 'PP30') {
          return '30%'
        } else {
          return '40%'
        }
      } else if (this.editedItem.tahun > 2021) {
        return '30%'
      } else {
        return '40%'
      }
    },

    hasilnilaiSKP: function () {
      if (this.editedItem.tahun > 2021) {
        let hasil = this.editedItem.nilai.nilaiSkp * 0.7
        return hasil
      } else if (this.editedItem.tahun < 2021) {
        let hasil = this.editedItem.nilai.nilaiSkp * 0.6
        return hasil
      } else if (this.editedItem.tahun == 2021) {
        if (this.editedItem.jenisPeraturanKinerja == 'PP30') {
          let hasil = this.editedItem.nilai.nilaiSkp * 0.7
          return hasil
        } else {
          let hasil = this.editedItem.nilai.nilaiSkp * 0.6
          return hasil
        }
      } else {
        let hasil = this.editedItem.nilai.nilaiSkp * 0.7
        return hasil
      }

      // if (this.editedItem.tahun > 2018) {
      //   let hasil = this.editedItem.nilai.nilaiSkp * 0.7
      //   return parseFloat(hasil).toFixed(2)
      // } else {
      //   let hasil = this.editedItem.nilai.nilaiSkp * 0.6
      //   return parseFloat(hasil).toFixed(2)
      // }
    },

    hasilnilaiPerilakuKerja: function () {
      if (this.editedItem.tahun > 2021) {
        let hasil = this.nilaiPerilakuKerja * 0.3
        return hasil
      } else if (this.editedItem.tahun < 2021) {
        let hasil = this.nilaiPerilakuKerja * 0.4
        return hasil
      } else if (this.editedItem.tahun == 2021) {
        if (this.editedItem.jenisPeraturanKinerja == 'PP30') {
          let hasil = this.nilaiPerilakuKerja * 0.3
          return hasil
        } else {
          let hasil = this.nilaiPerilakuKerja * 0.4
          return hasil
        }
      } else {
        let hasil = this.nilaiPerilakuKerja * 0.3
        return hasil
      }

      // if (this.editedItem.tahun > 2018) {
      //   let hasil = this.nilaiPerilakuKerja * 0.3
      //   return parseFloat(hasil).toFixed(2)
      // } else {
      //   let hasil = this.nilaiPerilakuKerja * 0.4
      //   return parseFloat(hasil).toFixed(2)
      // }
    },

    nilaiPerilakuKerja: function () {
      if (this.editedItem.jenisJabatan == 1) {
        let hasil
        let OP = parseInt(this.editedItem.nilai.orientasiPelayanan)
        let KO = parseInt(this.editedItem.nilai.komitmen)
        let KE = parseInt(this.editedItem.nilai.kerjasama)
        let INT = parseInt(this.editedItem.nilai.integritas)
        let DIS = parseInt(this.editedItem.nilai.disiplin)
        let IK = parseInt(this.editedItem.nilai.inisiatifKerja)
        let KEP = parseInt(this.editedItem.nilai.kepemimpinan)

        if (this.editedItem.tahun == 2021) {
          if (this.editedItem.jenisPeraturanKinerja == 'PP30') {
            hasil = (OP + KO + KE + IK + KEP) / 5
          } else {
            hasil = (OP + KO + KE + INT + DIS + KEP) / 6
          }
        } else if (this.tahun > 2021) {
          hasil = (OP + KO + KE + IK + KEP) / 5
        } else {
          hasil = (OP + KO + KE + INT + DIS + KEP) / 6
        }
        return parseFloat(hasil).toFixed(2)
      } else {
        let hasil
        let OP = parseInt(this.editedItem.nilai.orientasiPelayanan)
        let KO = parseInt(this.editedItem.nilai.komitmen)
        let KE = parseInt(this.editedItem.nilai.kerjasama)
        let INT = parseInt(this.editedItem.nilai.integritas)
        let DIS = parseInt(this.editedItem.nilai.disiplin)
        let IK = parseInt(this.editedItem.nilai.inisiatifKerja)

        if (this.editedItem.jenisPeraturanKinerja == 'PP30') {
          hasil = (OP + KO + KE + IK) / 4
        } else {
          hasil = (OP + KO + KE + INT + DIS) / 5
        }

        // hasil = (OP + KO + KE + INT + DIS) / 5
        return parseFloat(hasil).toFixed(2)
      }
    },

    nilaiPrestasiKerja: function () {
      let nilaiSKP = this.hasilnilaiSKP
      let nilaiPK = this.hasilnilaiPerilakuKerja
      let hasil = nilaiSKP + nilaiPK
      return parseFloat(hasil).toFixed(2)
    },

    jumlah: function () {
      let hasil
      let OP = parseInt(this.editedItem.nilai.orientasiPelayanan)
      let KO = parseInt(this.editedItem.nilai.komitmen)
      let KE = parseInt(this.editedItem.nilai.kerjasama)
      let INT = parseInt(this.editedItem.nilai.integritas)
      let DIS = parseInt(this.editedItem.nilai.disiplin)
      let IK = parseInt(this.editedItem.nilai.inisiatifKerja)
      let KEP = parseInt(this.editedItem.nilai.kepemimpinan)

      hasil = OP + KO + KE + INT + DIS + IK + KEP
      return hasil
    },

    tahun: function () {
      return this.editedItem.tahun
    },

    jenisPP: function () {
      return this.editedItem.jenisPeraturanKinerja
    },
  },

  watch: {
    async ModalEdit(value) {
      if (value) {
        this.refJenisjabatan = await getRef.Jenisjabatan(this.token)
      }
    },

    tahun(value) {
      if (this.editedItem.jenisJabatan == 1) {
        if (value == 2021) {
          if (this.jenisPP == 'PP30') {
            this.inisiatifkerjaView = true
            this.integritasView = false
            this.disiplinView = false
            this.editedItem.nilai.integritas = 0
            this.editedItem.nilai.disiplin = 0
          } else {
            this.inisiatifkerjaView = false
            this.integritasView = true
            this.disiplinView = true
            this.editedItem.nilai.inisiatifKerja = 0
          }
        } else if (value > 2021) {
          this.inisiatifkerjaView = true
          this.integritasView = false
          this.disiplinView = false
          this.editedItem.nilai.integritas = 0
          this.editedItem.nilai.disiplin = 0
        } else {
          this.inisiatifkerjaView = false
          this.integritasView = true
          this.disiplinView = true
          this.editedItem.nilai.inisiatifKerja = 0
        }
      } else {
        if (value == 2021) {
          if (this.jenisPP == 'PP30') {
            this.inisiatifkerjaView = true
            this.integritasView = false
            this.disiplinView = false
            this.editedItem.nilai.integritas = 0
            this.editedItem.nilai.disiplin = 0
          } else {
            this.inisiatifkerjaView = false
            this.integritasView = true
            this.disiplinView = true
            this.editedItem.nilai.inisiatifKerja = 0
          }
        } else if (value > 2021) {
          this.inisiatifkerjaView = true
          this.integritasView = false
          this.disiplinView = false
          this.editedItem.nilai.integritas = 0
          this.editedItem.nilai.disiplin = 0
        } else {
          this.inisiatifkerjaView = false
          this.integritasView = true
          this.disiplinView = true
          this.editedItem.nilai.inisiatifKerja = 0
        }
      }
    },

    jenisPP(value) {
      if (this.editedItem.jenisJabatan == 1) {
        if (this.editedItem.tahun == 2021) {
          if (value == 'PP30') {
            this.inisiatifkerjaView = true
            this.integritasView = false
            this.disiplinView = false
            this.editedItem.nilai.integritas = 0
            this.editedItem.nilai.disiplin = 0
          } else {
            this.inisiatifkerjaView = false
            this.integritasView = true
            this.disiplinView = true
            this.editedItem.nilai.inisiatifKerja = 0
          }
        }
      } else {
        if (this.editedItem.tahun == 2021) {
          if (value == 'PP30') {
            this.inisiatifkerjaView = true
            this.integritasView = false
            this.disiplinView = false
            this.editedItem.nilai.integritas = 0
            this.editedItem.nilai.disiplin = 0
          } else {
            this.inisiatifkerjaView = false
            this.integritasView = true
            this.disiplinView = true
            this.editedItem.nilai.inisiatifKerja = 0
          }
        }
      }
    },
  },

  methods: {
    validate() {
      return this.$refs.form.validate()
    },

    reset() {
      this.rwskp = {
        id: '',
        sapk: {
          id: '',
        },
        jenisJabatan: '',
        tahun: '',
        jenisPeraturanKinerja: '',
        nilai: {
          nilaiSkp: '',
          orientasiPelayanan: '',
          integritas: '',
          komitmen: '',
          disiplin: '',
          kerjasama: '',
          nilaiPerilakuKerja: '',
          nilaiPrestasiKerja: '',
          kepemimpinan: '',
          inisiatifKerja: '',
          jumlah: '',
          nilairatarata: '',
        },
        pejabatPenilai: {
          penilaiNipNrp: '',
          penilaiNama: '',
          penilaiUnorNama: '',
          penilaiJabatan: '',
          penilaiGolongan: '',
          penilaiTmtGolongan: '',
          statusPenilai: '',
        },
        atasanPejabatPenilai: {
          atasanPenilaiNipNrp: '',
          atasanPenilaiNama: '',
          atasanPenilaiUnorNama: '',
          atasanPenilaiJabatan: '',
          atasanPenilaiGolongan: '',
          atasanPenilaiTmtGolongan: '',
          statusAtasanPenilai: '',
        },
        pnsUserId: '',
        NCSISTIME: '',
      }
    },

    pejabatPenilai() {
      this.btnLoadingCariPP = false
      this.messageNIPCari = ''
      const token = Cookie.get('token')
      const url =
        apiURL.baseURL +
        'skp/cari/' +
        this.editedItem.pejabatPenilai.penilaiNipNrp

      this.http
        .get(
          url,
          { headers: apiURL.Config(token).headers },
          apiURL.Config(token).data
        )
        .then((response) => {
          if (response.data.success) {
            this.editedItem.pejabatPenilai.penilaiNama =
              response.data.mapData.DataUtama.nama
            this.editedItem.pejabatPenilai.penilaiUnorNama =
              response.data.mapData.DataUtama.unorIndukNama
            this.editedItem.pejabatPenilai.penilaiJabatan =
              response.data.mapData.DataUtama.jabatanNama
            this.editedItem.pejabatPenilai.penilaiGolongan =
              response.data.mapData.DataUtama.golRuangAkhir
            this.editedItem.pejabatPenilai.penilaiTmtGolongan =
              response.data.mapData.DataUtama.tmtGolAkhir
            this.btnLoadingCariPP = true
          } else {
            this.messageNIPCari = response.data.message
            this.btnLoadingCariPP = true
          }
        })
        .catch((error) => {
          this.messageNIPCari = ''
          if (error.response.status == 401) {
            Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status)
            this.btnLoadingCariPP = true
          } else {
            console.log('Error', error.message)
            this.btnLoadingCariPP = true
          }
        })
    },

    atasanPenilai() {
      this.btnLoadingCariAtasanPP = false
      this.messageAtasanNIPCari = ''
      const token = Cookie.get('token')
      const url =
        apiURL.baseURL +
        'skp/cari/' +
        this.editedItem.atasanPejabatPenilai.atasanPenilaiNipNrp

      this.http
        .get(
          url,
          { headers: apiURL.Config(token).headers },
          apiURL.Config(token).data
        )
        .then((response) => {
          if (response.data.success) {
            this.editedItem.atasanPejabatPenilai.atasanPenilaiNama =
              response.data.mapData.DataUtama.nama
            this.editedItem.atasanPejabatPenilai.atasanPenilaiUnorNama =
              response.data.mapData.DataUtama.unorIndukNama
            this.editedItem.atasanPejabatPenilai.atasanPenilaiJabatan =
              response.data.mapData.DataUtama.jabatanNama
            this.editedItem.atasanPejabatPenilai.atasanPenilaiGolongan =
              response.data.mapData.DataUtama.golRuangAkhir
            this.editedItem.atasanPejabatPenilai.atasanPenilaiTmtGolongan =
              response.data.mapData.DataUtama.tmtGolAkhir
            this.btnLoadingCariAtasanPP = true
          } else {
            this.messageAtasanNIPCari = response.data.message
            this.btnLoadingCariAtasanPP = true
          }
        })
        .catch((error) => {
          this.messageAtasanNIPCari = ''
          if (error.response.status == 401) {
            Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status)
            this.btnLoadingCariAtasanPP = true
          } else {
            console.log('Error', error.message)
            this.btnLoadingCariAtasanPP = true
          }
        })
    },

    async edit() {
      this.btnLoading = false
      this.validate()
      if (this.validate()) {
        const token = await Cookie.get('token')
        let data = {
          id: this.editedItem.id,
          sapk: {
            id: this.editedItem.sapk.id,
          },
          jenisJabatan: this.editedItem.jenisJabatan,
          jenisPeraturanKinerja: this.editedItem.jenisPeraturanKinerja,
          tahun: this.editedItem.tahun,
          nilai: {
            nilaiSkp: this.editedItem.nilai.nilaiSkp,
            orientasiPelayanan: this.editedItem.nilai.orientasiPelayanan,
            integritas: this.editedItem.nilai.integritas,
            komitmen: this.editedItem.nilai.komitmen,
            disiplin: this.editedItem.nilai.disiplin,
            kerjasama: this.editedItem.nilai.kerjasama,
            nilaiPerilakuKerja: this.nilaiPerilakuKerja,
            nilaiPrestasiKerja: this.nilaiPrestasiKerja,
            kepemimpinan: this.editedItem.nilai.kepemimpinan,
            inisiatifKerja: this.editedItem.nilai.inisiatifKerja,
            jumlah: this.jumlah,
            nilairatarata: this.nilaiPerilakuKerja,
          },

          pejabatPenilai: {
            pejabatPenilai: '',
            penilaiNipNrp: this.editedItem.pejabatPenilai.penilaiNipNrp,
            penilaiNama: this.editedItem.pejabatPenilai.penilaiNama,
            penilaiUnorNama: this.editedItem.pejabatPenilai.penilaiUnorNama,
            penilaiJabatan: this.editedItem.pejabatPenilai.penilaiJabatan,
            penilaiGolongan: this.editedItem.pejabatPenilai.penilaiGolongan,
            penilaiTmtGolongan:
              this.editedItem.pejabatPenilai.penilaiTmtGolongan,
            statusPenilai: 'PNS',
          },

          atasanPejabatPenilai: {
            atasanPejabatPenilai: '',
            atasanPenilaiNipNrp:
              this.editedItem.atasanPejabatPenilai.atasanPenilaiNipNrp,
            atasanPenilaiNama:
              this.editedItem.atasanPejabatPenilai.atasanPenilaiNama,
            atasanPenilaiUnorNama:
              this.editedItem.atasanPejabatPenilai.atasanPenilaiUnorNama,
            atasanPenilaiJabatan:
              this.editedItem.atasanPejabatPenilai.atasanPenilaiJabatan,
            atasanPenilaiGolongan:
              this.editedItem.atasanPejabatPenilai.atasanPenilaiGolongan,
            atasanPenilaiTmtGolongan:
              this.editedItem.atasanPejabatPenilai.atasanPenilaiTmtGolongan,
            statusAtasanPenilai: 'PNS',
          },
        }

        const url = apiURL.baseURL + 'skp/nip/' + this.get_nip
        this.http
          .put(url, data, { headers: apiURL.Update(token).headers })
          .then((response) => {
            this.btnLoading = true
            if (response.data.success) {
              refreshView.commit('refreshData', true)
              refreshView.commit('alert', response.data.message)
              refreshView.commit('berhasilAlert', true)
              refreshView.commit('gagalAlert', false)
              refreshView.commit('success', response.data.success)
            } else {
              refreshView.commit('refreshData', true)
              refreshView.commit('alert', response.data.message)
              refreshView.commit('gagalAlert', true)
              refreshView.commit('berhasilAlert', false)
              refreshView.commit('success', response.data.success)
            }
            this.fKosong = true
            this.closeModal()
          })
          .catch((error) => {
            if (error.response.status == 401) {
              Auth.logout(this.token)
            } else if (error.response) {
              refreshView.commit('refreshData', true)
              refreshView.commit('alert', error.response.data.message)
              refreshView.commit('gagalAlert', true)
              refreshView.commit('berhasilAlert', false)
              refreshView.commit('success', error.response.data.success)
              console.log(error.response.status)
              this.btnLoading = true
              this.closeModal()
            } else {
              refreshView.commit('refreshData', true)
              refreshView.commit('alert', error.response.data.message)
              refreshView.commit('gagalAlert', true)
              refreshView.commit('berhasilAlert', false)
              refreshView.commit('success', error.response.data.success)
              console.log(error.response.status)
              console.log('Error', error.message)
              this.btnLoading = true
              this.closeModal()
            }
          })
      } else {
        window.scrollTo(0, 0)
        this.fKosong = true
        this.btnLoading = true
      }
    },

    convertPersen(value) {
      return parseFloat(value).toFixed(2) + '%'
    },

    onChange(value) {
      if (value == 3) {
        this.dialog = true
      }
      if (value == 1 || this.editedItem.jenisJabatan == 1) {
        this.kepemimpinanView = true
        this.pembagi = 6
      } else {
        this.kepemimpinanView = false
        this.pembagi = 5
      }
    },

    onJPP(value) {
      if (value == 'PP30') {
        this.inisiatifkerjaView = true
        this.integritasView = false
        this.disiplinView = false
        this.nilaiINT = 0
        this.nilaiDIS = 0
        this.pembagi = 4
      } else {
        this.inisiatifkerjaView = false
        this.integritasView = true
        this.disiplinView = true
        this.nilaiIK = 0
        this.pembagi = 5
      }
    },

    tutupDialog() {
      this.jenisJabatan = ''
      this.dialog = false
    },

    predikat(value) {
      let angka = parseInt(value)
      let predikat
      if (angka <= 50) {
        predikat = 'Sangat Kurang'
      } else if (angka >= 51 && angka <= 61) {
        predikat = 'Kurang'
      } else if (angka >= 61 && angka <= 75) {
        predikat = 'Cukup'
      } else if (angka >= 76 && angka <= 90) {
        predikat = 'Baik'
      } else if (angka >= 91 && angka <= 100) {
        predikat = 'Sangat Baik'
      } else {
        predikat = 'Tidak Ada Dalam Daftar Predikat'
      }

      return predikat
    },

    async closeModal() {
      await this.reset()
      await modal_editState.commit('editModal', Object.assign({}, this.rwskp))
      await refreshView.commit('refreshData', true)
      this.ModalEdit = false
    },
  },
}
</script>
