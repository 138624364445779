<template>
  <v-dialog v-model="ModalToSIMASN" persistent max-width="40%">
    <v-card>
      <v-card-title class="headline"> Sinkron Data Ke SIMASN? </v-card-title>
      <v-card-text>
        Data SKP yang anda pilih akan diselaraskan dengan data SKP di
        SIMASN!</v-card-text
      >
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" small depressed @click="ModalToSIMASN = false">
          Batal
        </v-btn>
        <v-btn
          v-if="btnLoading"
          color="green"
          small
          depressed
          dark
          @click="toSAPK()"
        >
          Ya
        </v-btn>
        <v-btn v-else color="green" small depressed dark loading> Ya </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from '@/helper/cookie.js'
import apiURL from '@/helper/getURL.js'
import Auth from '@/helper/auth.js'

import modal_tosimasnState from '@/store/asn/rwskp/modal_to_simasn'
import refreshView from '@/store/asn/rwskp/viewRwskp'

export default {
  name: 'rwskpModalToSIMASN',
  data() {
    return {
      token: Cookie.get('token'),
      btnLoading: true,
      get_nip: '',
    }
  },

  computed: {
    ModalToSIMASN: {
      get() {
        return modal_tosimasnState.state.ModalToSIMASN
      },

      set(value) {
        modal_tosimasnState.commit('toggleModal', value)
      },
    },

    tosimasnItem: {
      get() {
        return modal_tosimasnState.state.rwskp
      },
    },

    editedDatautama: {
      get() {
        return modal_tosimasnState.state.dataUtama
      },
    },
  },

  methods: {
    async toSAPK() {
      this.btnLoading = false
      let token = await Cookie.get('token')
      this.get_nip = this.$route.params.id

      let data = {
        jenisJabatan: this.tosimasnItem.jenisJabatan,
        tahun: this.tosimasnItem.tahun,
        nilai: {
          nilaiSkp: this.tosimasnItem.nilai.nilaiSkp,
          orientasiPelayanan: this.tosimasnItem.nilai.orientasiPelayanan,
          integritas: this.tosimasnItem.nilai.integritas,
          komitmen: this.tosimasnItem.nilai.komitmen,
          disiplin: this.tosimasnItem.nilai.disiplin,
          kerjasama: this.tosimasnItem.nilai.kerjasama,
          nilaiPerilakuKerja: this.tosimasnItem.nilai.nilaiPerilakuKerja,
          nilaiPrestasiKerja: this.tosimasnItem.nilai.nilaiPrestasiKerja,
          kepemimpinan: this.tosimasnItem.nilai.kepemimpinan,
          jumlah: this.tosimasnItem.nilai.jumlah,
          nilairatarata: this.tosimasnItem.nilai.nilairatarata,
        },

        pejabatPenilai: {
          pejabatPenilai: '',
          penilaiNipNrp: this.tosimasnItem.pejabatPenilai.penilaiNipNrp,
          penilaiNama: this.tosimasnItem.pejabatPenilai.penilaiNama,
          penilaiUnorNama: this.tosimasnItem.pejabatPenilai.penilaiUnorNama,
          penilaiJabatan: this.tosimasnItem.pejabatPenilai.penilaiJabatan,
          penilaiGolongan: this.tosimasnItem.pejabatPenilai.penilaiGolongan,
          penilaiTmtGolongan:
            this.tosimasnItem.pejabatPenilai.penilaiTmtGolongan,
          statusPenilai: 'PNS',
        },

        atasanPejabatPenilai: {
          atasanPejabatPenilai: '',
          atasanPenilaiNipNrp:
            this.tosimasnItem.atasanPejabatPenilai.atasanPenilaiNipNrp,
          atasanPenilaiNama:
            this.tosimasnItem.atasanPejabatPenilai.atasanPenilaiNama,
          atasanPenilaiUnorNama:
            this.tosimasnItem.atasanPejabatPenilai.atasanPenilaiUnorNama,
          atasanPenilaiJabatan:
            this.tosimasnItem.atasanPejabatPenilai.atasanPenilaiJabatan,
          atasanPenilaiGolongan:
            this.tosimasnItem.atasanPejabatPenilai.atasanPenilaiGolongan,
          atasanPenilaiTmtGolongan:
            this.tosimasnItem.atasanPejabatPenilai.atasanPenilaiTmtGolongan,
          statusAtasanPenilai: 'PNS',
        },
      }

      const url = apiURL.baseURL + 'skp/nip/' + this.get_nip
      this.http
        .post(url, data, { headers: apiURL.Update(token).headers })
        .then((response) => {
          this.btnLoading = true
          if (response.data.success) {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', response.data.message)
            refreshView.commit('berhasilAlert', true)
            refreshView.commit('gagalAlert', false)
            refreshView.commit('success', response.data.success)
          } else {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', response.data.message)
            refreshView.commit('gagalAlert', true)
            refreshView.commit('berhasilAlert', false)
            refreshView.commit('success', response.data.success)
          }
          this.ModalToSIMASN = false
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token)
          } else if (error.response) {
            this.btnLoading = true
            console.log(error.response.status)
          } else {
            this.btnLoading = true
            console.log('Error', error.message)
          }
        })
    },
  },
}
</script>
