<template>
  <v-dialog v-model="ModalToSIMASN" persistent max-width="40%">
    <v-card>
      <v-card-title class="headline"> Sinkron Data Ke IDASN? </v-card-title>
      <v-card-text>
        Data penghargaan yang anda pilih akan disalin ke data penghargaan di
        IDASN!
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" small depressed @click="closeModal()">
          Batal
        </v-btn>

        <v-btn
          color="primary"
          small
          depressed
          :loading="lbtn"
          :disabled="lbtn"
          @click="toSIMASN()"
        >
          Ya
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import modal_tosimasn from "@/store/asn/rwskp/modal_to_simasn";
import refreshView from "@/store/asn/rwskp/viewRwskp";

export default {
  name: "rwskpModalToSIMASN",
  data: () => ({
    token: Cookie.get("token"),
    lbtn: false,

    get_nip: "",
    file: null,
    doc: null,
  }),

  computed: {
    ModalToSIMASN: {
      get() {
        return modal_tosimasn.state.ModalToSIMASN;
      },

      set(value) {
        modal_tosimasn.commit("toggleModal", value);
      },
    },

    item: {
      get() {
        return modal_tosimasn.state.rwskp;
      },
    },

    editedDatautama: {
      get() {
        return modal_tosimasn.state.dataUtama;
      },
    },
  },

  methods: {
    async toSIMASN() {
      this.lbtn = true;

      const url =
        process.env.VUE_APP_ASN + "kinerja/nip/" + this.$route.params.id;

      const data = new FormData();
      data.append("doc", this.doc);
      data.append("tahun", this.item.tahun);
      data.append(
        "hasilKinerjaNilai",
        this.item.kinerja.hasilKinerjaNilai.slice(0, 1)
      );
      data.append(
        "perilakuKerjaNilai",
        this.item.kinerja.perilakuKerjaNilai.slice(0, 1)
      );
      data.append(
        "kuadranKinerjaNilai",
        this.item.kinerja.kuadranKinerjaNilai.slice(0, 1)
      );
      data.append("penilaiStatus", this.item.pejabatPenilai.statusPenilai);
      data.append("penialaiNip", this.item.pejabatPenilai.nipPenilai);
      data.append("penilaiNama", this.item.pejabatPenilai.namaPenilai);
      data.append("penilaiUnorNama", this.item.pejabatPenilai.penilaiUnorNama);
      data.append(
        "penilaiJabatanNama",
        this.item.pejabatPenilai.penilaiJabatanNama
      );
      data.append("penilaiGolongan", "11");
      data.append("siasn_token", "");
      data.append("wsbkn_token", "");

      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.lbtn = false;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            this.lbtn = false;
            console.log(error.response.status);
          } else {
            this.lbtn = false;
            console.log("Error", error.message);
          }
        });
    },

    onUpload(value) {
      this.doc = value;
    },

    closeModal() {
      this.file = null;
      this.doc = null;
      this.ModalToSIMASN = false;
    },
  },
};
</script>
