<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="subtitle-1">
          Edit Riwayat Sasaran Kinerja Pegawai
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="mx-5 mt-5">
          <v-row>
            <v-col cols="12" md="4">
              <strong> Tahun </strong>

              <v-text-field
                v-model="eItem.tahun"
                class="mt-2"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </div>

        <div class="mx-5 mt-5" v-if="eItem.tahun < 2022">
          <v-alert type="error">
            Silahkan pilih tahun SKP <strong>Lama (2022 Kebawah)</strong> pada
            tabel terlebih dahulu untuk melakukan pengimputan
            <strong>SKP Lama</strong>
          </v-alert>
        </div>

        <div class="mx-5 pb-5 mt-n5" v-else>
          <v-card outlined color="blue lighten-4" class="pa-1 mb-6 text-center">
            <strong>Kinerja</strong>
          </v-card>

          <div v-if="eItem.kinerja">
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12" md="4">
                    <strong> Hasil Kinerja</strong>

                    <v-select
                      v-model="eItem.kinerja.hasilKinerjaNilai"
                      class="mt-2"
                      :items="hasil_kinerja"
                      placeholder="Pilih Nilai Hasil Kerja"
                      item-value="id"
                      item-text="id"
                      outlined
                      dense
                      :rules="fRules"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="8">
                    <strong> Rating Perilaku Kinerja </strong>

                    <v-text-field
                      v-model="eItem.kinerja.hasilKinerja"
                      class="mt-2"
                      outlined
                      dense
                      filled
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12" md="4">
                    <strong> Perilaku Kerja </strong>

                    <v-select
                      v-model="eItem.kinerja.perilakuKerjaNilai"
                      class="mt-2"
                      :items="perilaku_kerja"
                      placeholder="Pilih Nilai Perilaku Kerja"
                      item-value="id"
                      item-text="id"
                      outlined
                      dense
                      :rules="fRules"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="8">
                    <strong> Rating Perilaku Kerja </strong>

                    <v-text-field
                      v-model="eItem.kinerja.perilakuKerja"
                      class="mt-2"
                      outlined
                      dense
                      filled
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="mt-n7">
              <v-col cols="12" md="6">
                <strong> Kuadran Kinerja </strong>

                <v-text-field
                  v-model="eItem.kinerja.kuadranKinerjaNilai"
                  class="mt-2"
                  outlined
                  dense
                  filled
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <strong> Rating Kuadran Kinerja </strong>

                <v-text-field
                  v-model="eItem.kinerja.kuadranKinerja"
                  class="mt-2"
                  outlined
                  dense
                  filled
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <v-card
            outlined
            color="blue lighten-4"
            class="pa-1 mb-6 mt-2 text-center"
          >
            <strong>Pejabat Penilai</strong>
          </v-card>

          <div v-if="eItem.pejabatPenilai">
            <v-row>
              <v-col cols="12" md="6">
                <strong> Status Penilai </strong>

                <v-radio-group v-model="eItem.pejabatPenilai.statusPenilai" row>
                  <v-radio label="ASN" value="ASN"></v-radio>
                  <v-radio label="Non ASN" value="NON ASN"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12" md="6" v-if="eItem.pejabatPenilai.statusPenilai">
                <strong> NIP Pejabat Penilai </strong>

                <v-text-field
                  v-model="eItem.pejabatPenilai.nipPenilai"
                  class="mt-2"
                  outlined
                  dense
                  append-icon="mdi-magnify"
                  :rules="fRules"
                ></v-text-field>
              </v-col>
            </v-row>

            <div v-if="eItem.pejabatPenilai.statusPenilai">
              <v-row class="mt-n7">
                <v-col cols="12" md="6">
                  <strong> Nama Pejabat Penilai </strong>

                  <v-text-field
                    v-model="eItem.pejabatPenilai.namaPenilai"
                    class="mt-2"
                    outlined
                    dense
                    :rules="fRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <strong> Golongan Pejabat Penilai </strong>

                  <v-select
                    v-model="eItem.pejabatPenilai.penilaiGolongan"
                    :items="refGolongan"
                    class="mt-2"
                    item-value="id"
                    item-text="nama"
                    outlined
                    dense
                    :rules="fRules"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row class="mt-n7">
                <v-col cols="12" md="6">
                  <strong> Unit Organisasi Pejabat Penilai </strong>

                  <v-text-field
                    v-model="eItem.pejabatPenilai.penilaiUnorNama"
                    class="mt-2"
                    outlined
                    dense
                    :rules="fRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <strong> Jabatan Pejabat Penilai </strong>

                  <v-text-field
                    v-model="eItem.pejabatPenilai.penilaiJabatanNama"
                    class="mt-2"
                    outlined
                    dense
                    :rules="fRules"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="mt-n7">
                <v-col cols="12" md="12">
                  <strong> Upload Dokumen </strong>

                  <v-file-input
                    class="mt-2"
                    outlined
                    dense
                    flat
                    prepend-icon
                    accept=".pdf"
                    label="Dokumen SKP"
                    placeholder="Pilih File"
                    append-icon="mdi-paperclip"
                    @change="onUpload"
                  ></v-file-input>
                  <!-- <v-chip
                    class="mt-n10"
                    color="success"
                    small
                    @click="download(eItem.document.slug_891)"
                    v-if="eItem.document.slug_891.dok_nama != null"
                  >
                    Lihat File
                    {{ eItem.document.slug_891.dok_nama }}
                  </v-chip> -->
                  <v-chip
                    class="mt-n10"
                    color="success"
                    small
                    v-if="eItem.document.slug_891.dok_nama != null"
                  >
                    File
                    {{ eItem.document.slug_891.dok_nama }}
                  </v-chip>
                  <v-chip class="mt-n10" color="error" small v-else>
                    Belum Ada File
                  </v-chip>
                </v-col>
              </v-row>

              <v-divider class="mb-5"></v-divider>

              <div class="text-right">
                <v-btn
                  color="primary"
                  :loading="lbtn"
                  :disabled="lbtn"
                  @click="simpan()"
                >
                  Simpan
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-form>

      <v-dialog v-model="modalPDF" :max-width="dekstop">
        <v-card height="90vh" style="overflow: hidden">
          <v-toolbar dark color="primary" dense flat>
            <v-toolbar-title>{{ namaPDF }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closePDF()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card flat class="pa-2">
            <v-progress-linear
              indeterminate
              color="primary"
              v-if="lbtn"
            ></v-progress-linear>

            <div v-else>
              <embed
                :src="defaultPDF"
                type="application/pdf"
                style="height: 80vh; width: 100%"
              />
            </div>
          </v-card>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";
import getRef from "@/helper/getReferensi.js";

import refreshView from "@/store/asn/rwskp/viewRwskp";
import modal_edit from "@/store/asn/rwskp/rwskp_new/modal_edit";

export default {
  data: () => ({
    token: Cookie.get("token"),
    token_siasn: Cookie.get("token_siasn"),
    token_wsbkn: Cookie.get("token_wsbkn"),

    modalPDF: false,
    lbtn: false,
    dekstop: "75%",
    defaultPDF: "",
    namaPDF: "",

    get_nip: "",
    doc: "",

    refGolongan: [],

    hasil_kinerja: [],
    perilaku_kerja: [],
    kuadran_kinerja: [],

    fRules: [(value) => !!value || "Tidak Boleh Kosong"],
  }),

  watch: {
    async ModalEdit() {
      this.refGolongan = await getRef.Golongan(this.token);
    },

    hasilKinerja() {
      if (this.eItem.kinerja.hasilKinerjaNilai == 1) {
        let hasil = this.hasil_kinerja.find((value) => value.id == 1);
        this.eItem.kinerja.hasilKinerja = hasil.nama;
      } else if (this.eItem.kinerja.hasilKinerjaNilai == 2) {
        let hasil = this.hasil_kinerja.find((value) => value.id == 2);
        this.eItem.kinerja.hasilKinerja = hasil.nama;
      } else if (this.eItem.kinerja.hasilKinerjaNilai == 3) {
        let hasil = this.hasil_kinerja.find((value) => value.id == 3);
        this.eItem.kinerja.hasilKinerja = hasil.nama;
      } else {
        this.eItem.kinerja.hasilKinerja = "";
      }
    },

    perilakuKerja() {
      if (this.eItem.kinerja.perilakuKerjaNilai == 1) {
        let hasil = this.perilaku_kerja.find((value) => value.id == 1);
        this.eItem.kinerja.perilakuKerja = hasil.nama;
      } else if (this.eItem.kinerja.perilakuKerjaNilai == 2) {
        let hasil = this.perilaku_kerja.find((value) => value.id == 2);
        this.eItem.kinerja.perilakuKerja = hasil.nama;
      } else if (this.eItem.kinerja.perilakuKerjaNilai == 3) {
        let hasil = this.perilaku_kerja.find((value) => value.id == 3);
        this.eItem.kinerja.perilakuKerja = hasil.nama;
      } else {
        this.eItem.kinerja.perilakuKerja = "";
      }
    },

    hasilKinerjaNilai() {
      if (
        this.eItem.kinerja.hasilKinerjaNilai == 1 &&
        this.eItem.kinerja.perilakuKerjaNilai == 1
      ) {
        let hasil = this.kuadran_kinerja.find((value) => value.id == 1);
        this.eItem.kinerja.kuadranKinerjaNilai = hasil.id;
        this.eItem.kinerja.kuadranKinerja = hasil.nama;
      } else if (
        this.eItem.kinerja.hasilKinerjaNilai == 1 &&
        this.eItem.kinerja.perilakuKerjaNilai == 2
      ) {
        let hasil = this.kuadran_kinerja.find((value) => value.id == 2);
        this.eItem.kinerja.kuadranKinerjaNilai = hasil.id;
        this.eItem.kinerja.kuadranKinerja = hasil.nama;
      } else if (
        this.eItem.kinerja.hasilKinerjaNilai == 2 &&
        this.eItem.kinerja.perilakuKerjaNilai == 1
      ) {
        let hasil = this.kuadran_kinerja.find((value) => value.id == 2);
        this.eItem.kinerja.kuadranKinerjaNilai = hasil.id;
        this.eItem.kinerja.kuadranKinerja = hasil.nama;
      } else if (
        this.eItem.kinerja.hasilKinerjaNilai == 2 &&
        this.eItem.kinerja.perilakuKerjaNilai == 2
      ) {
        let hasil = this.kuadran_kinerja.find((value) => value.id == 2);
        this.eItem.kinerja.kuadranKinerjaNilai = hasil.id;
        this.eItem.kinerja.kuadranKinerja = hasil.nama;
      } else if (
        this.eItem.kinerja.hasilKinerjaNilai == 3 &&
        this.eItem.kinerja.perilakuKerjaNilai == 1
      ) {
        let hasil = this.kuadran_kinerja.find((value) => value.id == 3);
        this.eItem.kinerja.kuadranKinerjaNilai = hasil.id;
        this.eItem.kinerja.kuadranKinerja = hasil.nama;
      } else if (
        this.eItem.kinerja.hasilKinerjaNilai == 3 &&
        this.eItem.kinerja.perilakuKerjaNilai == 2
      ) {
        let hasil = this.kuadran_kinerja.find((value) => value.id == 3);
        this.eItem.kinerja.kuadranKinerjaNilai = hasil.id;
        this.eItem.kinerja.kuadranKinerja = hasil.nama;
      } else if (
        this.eItem.kinerja.hasilKinerjaNilai == 1 &&
        this.eItem.kinerja.perilakuKerjaNilai == 3
      ) {
        let hasil = this.kuadran_kinerja.find((value) => value.id == 4);
        this.eItem.kinerja.kuadranKinerjaNilai = hasil.id;
        this.eItem.kinerja.kuadranKinerja = hasil.nama;
      } else if (
        this.eItem.kinerja.hasilKinerjaNilai == 2 &&
        this.eItem.kinerja.perilakuKerjaNilai == 3
      ) {
        let hasil = this.kuadran_kinerja.find((value) => value.id == 4);
        this.eItem.kinerja.kuadranKinerjaNilai = hasil.id;
        this.eItem.kinerja.kuadranKinerja = hasil.nama;
      } else if (
        this.eItem.kinerja.hasilKinerjaNilai == 3 &&
        this.eItem.kinerja.perilakuKerjaNilai == 3
      ) {
        let hasil = this.kuadran_kinerja.find((value) => value.id == 5);
        this.eItem.kinerja.kuadranKinerjaNilai = hasil.id;
        this.eItem.kinerja.kuadranKinerja = hasil.nama;
      } else {
        this.eItem.kinerja.kuadranKinerjaNilai = "";
        this.eItem.kinerja.kuadranKinerja = "";
      }
    },

    perilakuKerjaNilai() {
      if (
        this.eItem.kinerja.hasilKinerjaNilai == 1 &&
        this.eItem.kinerja.perilakuKerjaNilai == 1
      ) {
        let hasil = this.kuadran_kinerja.find((value) => value.id == 1);
        this.eItem.kinerja.kuadranKinerjaNilai = hasil.id;
        this.eItem.kinerja.kuadranKinerja = hasil.nama;
      } else if (
        this.eItem.kinerja.hasilKinerjaNilai == 1 &&
        this.eItem.kinerja.perilakuKerjaNilai == 2
      ) {
        let hasil = this.kuadran_kinerja.find((value) => value.id == 2);
        this.eItem.kinerja.kuadranKinerjaNilai = hasil.id;
        this.eItem.kinerja.kuadranKinerja = hasil.nama;
      } else if (
        this.eItem.kinerja.hasilKinerjaNilai == 2 &&
        this.eItem.kinerja.perilakuKerjaNilai == 1
      ) {
        let hasil = this.kuadran_kinerja.find((value) => value.id == 2);
        this.eItem.kinerja.kuadranKinerjaNilai = hasil.id;
        this.eItem.kinerja.kuadranKinerja = hasil.nama;
      } else if (
        this.eItem.kinerja.hasilKinerjaNilai == 2 &&
        this.eItem.kinerja.perilakuKerjaNilai == 2
      ) {
        let hasil = this.kuadran_kinerja.find((value) => value.id == 2);
        this.eItem.kinerja.kuadranKinerjaNilai = hasil.id;
        this.eItem.kinerja.kuadranKinerja = hasil.nama;
      } else if (
        this.eItem.kinerja.hasilKinerjaNilai == 3 &&
        this.eItem.kinerja.perilakuKerjaNilai == 1
      ) {
        let hasil = this.kuadran_kinerja.find((value) => value.id == 3);
        this.eItem.kinerja.kuadranKinerjaNilai = hasil.id;
        this.eItem.kinerja.kuadranKinerja = hasil.nama;
      } else if (
        this.eItem.kinerja.hasilKinerjaNilai == 3 &&
        this.eItem.kinerja.perilakuKerjaNilai == 2
      ) {
        let hasil = this.kuadran_kinerja.find((value) => value.id == 3);
        this.eItem.kinerja.kuadranKinerjaNilai = hasil.id;
        this.eItem.kinerja.kuadranKinerja = hasil.nama;
      } else if (
        this.eItem.kinerja.hasilKinerjaNilai == 1 &&
        this.eItem.kinerja.perilakuKerjaNilai == 3
      ) {
        let hasil = this.kuadran_kinerja.find((value) => value.id == 4);
        this.eItem.kinerja.kuadranKinerjaNilai = hasil.id;
        this.eItem.kinerja.kuadranKinerja = hasil.nama;
      } else if (
        this.eItem.kinerja.hasilKinerjaNilai == 2 &&
        this.eItem.kinerja.perilakuKerjaNilai == 3
      ) {
        let hasil = this.kuadran_kinerja.find((value) => value.id == 4);
        this.eItem.kinerja.kuadranKinerjaNilai = hasil.id;
        this.eItem.kinerja.kuadranKinerja = hasil.nama;
      } else if (
        this.eItem.kinerja.hasilKinerjaNilai == 3 &&
        this.eItem.kinerja.perilakuKerjaNilai == 3
      ) {
        let hasil = this.kuadran_kinerja.find((value) => value.id == 5);
        this.eItem.kinerja.kuadranKinerjaNilai = hasil.id;
        this.eItem.kinerja.kuadranKinerja = hasil.nama;
      } else {
        this.eItem.kinerja.kuadranKinerjaNilai = "";
        this.eItem.kinerja.kuadranKinerja = "";
      }
    },
  },

  computed: {
    ModalEdit: {
      get() {
        return modal_edit.state.ModalEdit;
      },

      set(value) {
        modal_edit.commit("toggleModal", value);
      },
    },

    eItem: {
      get() {
        return modal_edit.state.rwskp;
      },

      set(value) {
        console.log(value);
      },
    },

    hasilKinerjaNilai: function () {
      if (this.eItem.kinerja) {
        return this.eItem.kinerja.hasilKinerjaNilai;
      } else {
        return "";
      }
    },

    perilakuKerjaNilai: function () {
      if (this.eItem.kinerja) {
        return this.eItem.kinerja.perilakuKerjaNilai;
      } else {
        return "";
      }
    },

    hasilKinerja: function () {
      if (this.eItem.kinerja) {
        return this.eItem.kinerja.hasilKinerjaNilai;
      } else {
        return "";
      }
    },

    perilakuKerja: function () {
      if (this.eItem.kinerja) {
        return this.eItem.kinerja.perilakuKerjaNilai;
      } else {
        return "";
      }
    },
  },

  mounted() {
    this.get_nip = this.$route.params.id;
    this.getRef();
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    getRef() {
      const url = process.env.VUE_APP_ASN + "ref/skp";

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.hasil_kinerja = res.data.mapData.referensi.hasil_kinerja;
          this.perilaku_kerja = res.data.mapData.referensi.perilaku_kerja;
          this.kuadran_kinerja = res.data.mapData.referensi.kuadran_kinerja;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    cari() {
      const url = process.env.VUE_APP_ASN + "kinerja/cari/" + this.penialaiNip;

      let data = {
        siasn_token: this.token_siasn,
      };

      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.eItem.pejabatPenilai.namaPenilai = res.data.mapData.nama;
          this.eItem.pejabatPenilai.penilaiGolongan =
            res.data.mapData.golongan.id;
          this.eItem.pejabatPenilai.penilaiUnorNama =
            res.data.mapData.unorIndukNama;
          this.eItem.pejabatPenilai.penilaiJabatanNama =
            res.data.mapData.jabatanNama;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    onUpload(value) {
      this.doc = value;
    },

    async simpan() {
      this.lbtn = true;

      if (this.validate()) {
        const url = process.env.VUE_APP_ASN + "kinerja/nip/" + this.get_nip;

        const data = new FormData();
        data.append("idSKP", this.eItem.id);
        data.append("SAPK_idSKP", this.eItem.sapk.id);
        data.append("doc", this.doc);
        data.append("dok_uri", this.eItem.document.slug_891.dok_uri);
        data.append("tahun", this.eItem.tahun);
        data.append("hasilKinerjaNilai", this.eItem.kinerja.hasilKinerjaNilai);
        data.append(
          "perilakuKerjaNilai",
          this.eItem.kinerja.perilakuKerjaNilai
        );
        data.append(
          "kuadranKinerjaNilai",
          this.eItem.kinerja.kuadranKinerjaNilai
        );
        data.append("penilaiStatus", this.eItem.pejabatPenilai.statusPenilai);
        data.append("penialaiNip", this.eItem.pejabatPenilai.nipPenilai);
        data.append("penilaiNama", this.eItem.pejabatPenilai.namaPenilai);
        data.append(
          "penilaiUnorNama",
          this.eItem.pejabatPenilai.penilaiUnorNama
        );
        data.append(
          "penilaiJabatanNama",
          this.eItem.pejabatPenilai.penilaiJabatanNama
        );
        data.append(
          "penilaiGolongan",
          this.eItem.pejabatPenilai.penilaiGolongan
        );
        data.append("siasn_token", this.token_siasn);
        data.append("wsbkn_token", this.token_wsbkn);

        this.http
          .put(url, data, {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then((response) => {
            if (response.data.success) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("berhasilAlert", true);
              refreshView.commit("gagalAlert", false);
              refreshView.commit("success", response.data.success);
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", response.data.success);
            }
            this.lbtn = false;
            this.closeModal();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              Auth.logout(this.token);
            } else if (error.response) {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", error.response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", error.response.data.success);
              console.log(error.response.status);
              this.lbtn = false;
              this.closeModal();
            } else {
              refreshView.commit("refreshData", true);
              refreshView.commit("alert", error.response.data.message);
              refreshView.commit("gagalAlert", true);
              refreshView.commit("berhasilAlert", false);
              refreshView.commit("success", error.response.data.success);
              console.log(error.response.status);
              console.log("Error", error.message);
              this.lbtn = false;
              this.closeModal();
            }
          });
      } else {
        window.scrollTo(0, 0);
        this.lbtn = false;
      }
    },

    download(value) {
      this.lbtn = true;
      this.modalPDF = true;
      this.namaPDF = value.dok_nama;
      let baseurl =
        "https://apimws.bkn.go.id:8243/apisiasn/1.0/download-dok?filePath=";

      const url = baseurl + value.dok_uri;

      this.http
        .get(url, {
          headers: {
            Auth: "bearer " + this.token_siasn,
            Authorization: "Bearer " + this.token_wsbkn,
          },
          responseType: "blob",
        })
        .then((res) => {
          this.defaultPDF = URL.createObjectURL(res.data);
          this.lbtn = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    closeModal() {
      this.ModalEdit = false;
    },

    closePDF() {
      this.modalPDF = false;
    },
  },
};
</script>
