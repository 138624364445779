import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalEdit: false,
    rwskp: {},

  },

  mutations: {
    toggleModal(state, value) {
      state.ModalEdit = value
    },

    editModal(state, value) {
      state.rwskp = value
    },
  }
})